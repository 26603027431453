// node --max_old_space_size=2048 ./node_modules/@angular/cli/bin/ng build --prod --build-optimizer --aot

const apiDomain = 'https://ott.shilpit.com/';
const appDomain = 'https://ott.shilpit.com/';
const webRTCHost = 'webrtc.vplayed.com';

export const environment = {
  production: true,
  userApiUrl: `${apiDomain}users/api/`,
  apiUrl: `${apiDomain}medias/api/`,
  userActionApiUrl: `${apiDomain}useractions/api/`,
  paymentApiUrl: `${apiDomain}payments/api/`,
  notificationApiUrl: `${apiDomain}notifications/api/`,
  commonApiUrl: `${apiDomain}common/api/`,
  searchApiUrl: `${apiDomain}search/elastic/`,
  analyticsApiUrl: `${apiDomain}analytics/api/`,
  domain: appDomain,

  webRTCBroadcastDomain: `https://${webRTCHost}:5443/`,
  webRTCSocketUrl: `wss://${webRTCHost}:5443/WebRTCAppEE/websocket`,
  socketUrl: 'wss://admin.vplayed.demo.contus.us/ws/',
  encryption_key: 'vplay@contus',
  GoogleOAuthClientId:
    '268190222189-0au6rsqe8sgalddlqpiaqhu6bgjp037s.apps.googleusercontent.com',
  FacebookAppId: '1600492580127527',
  googleRecaptcha: '6LekEMQUAAAAALOZuJIQRJbf8wJ9S0KFKQUFNF3A',
  googleMapKey: 'AIzaSyA4cI8fc5XyQAeGaDy7ZmVh7LSrMbTku98',
  latitude: 13.0156018,
  longitude: 80.1981568,
  defaultImage: 'https://d2rq7c4c4iu0a6.cloudfront.net/common/blank_new.png',
  crypto_iv: 'a32b97d84acba36667c7709de74fa510',
  crypto_key:
    '2f00f5fbea3c1c77e8d3e5536021fc1d46e803670879e01e9de64b387ea4dc68',
  AWS_accessKeyId: 'AKIAVBJWLCX4M37S7SPV',
  AWS_secretAccessKey: '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',
  AWS_region: 'us-east-1',
  AWS_personalize_trackingId: '848a144c-81bc-4d1c-ab84-bd7d509e8635',
  AWS_personalize_campaignArn:
    'arn:aws:personalize:us-east-1:346395710968:campaign/vplayed-user-active-video-slug-dataset-campaign'
};
