// import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
// import { ApiService } from '../../../core/services/api.service';
// import { environment } from 'src/environments/environment';
// import { NgForm } from '@angular/forms';
// @Component({
//   selector: 'app-coupon',
//   templateUrl: './coupon.component.html',
//   styleUrls: ['./coupon.component.scss']
// })

// export class CouponComponent implements OnInit {
//   @Input() receivedAmount: any;
//   @Output() messageToEmit = new EventEmitter<any>();
//   calculation: any;
//   submitted = true;
//   isRemove = false;
//   isHidden = false;
//   offer: any;
//   errorMessage: any;
//   statusCode: any;
//   successMessage: any;
//   amount: any;
//   tempAmount: any;
//   error: any = {};
//   code: any;
//   disableTextbox = false;
//   coupon: Object = {};
//   coupon_id: any;
//   trial: any;
//   trialVal: any;
//   constructor(private apiService: ApiService) {
//   }
//   ngOnInit() {
//     const couponResponse = localStorage.getItem('couponResponse');
//     if (couponResponse && couponResponse !== '') {
//       const code = JSON.parse(couponResponse);
//       this.code = code['code'];
//       this.couponCalculation(JSON.parse(couponResponse));
//     }
//   }
//   /**
//    * for handling the coupons
//    */
//   handleCoupon(form: NgForm) {
//     this.isHidden = false;
//     if (this.code !== undefined) {
//       this.apiService.callPostAPI('v2/coupon/verify', { 'code': this.code },
//         environment.paymentApiUrl).subscribe(res => {
//            console.log(res);
//           this.couponCalculation(res);
//         },
//           err => {
//             console.log(this.receivedAmount);
//             localStorage.setItem("myamount", this.receivedAmount);
//             this.successMessage = '';
//             this.errorMessage = err['error']['message'];
//             this.statusCode = err['error']['statusCode'];
//             this.receivedAmount = this.tempAmount;
//           });
//     }
//   }
//   /**
//    * for coupon calculations
//    * @param res
//    */
//   couponCalculation(res) {
//     this.tempAmount = this.receivedAmount;
//     // console.log(this.tempAmount);
//     this.coupon_id = res['response'].coupon_id;
//     //this.trial = res['response'].trial;
//     this.offer = res['response'].offer;
//     if (res['response']['offer_type'] === 'percentage') {
//       this.calculation = this.receivedAmount * this.offer / 100;
//       this.calculation = Math.round(this.calculation);
//       this.receivedAmount = this.receivedAmount - this.calculation;
//      } else {
//       this.calculation = this.offer;
//       this.receivedAmount = this.receivedAmount - this.offer;
//       // console.log('offer', this.receivedAmount);
//     }
//     this.coupon['amount'] = this.receivedAmount;
//     this.coupon['code'] = this.code;
//     this.coupon['coupon_id'] = this.coupon_id;
//     this.coupon['calculation'] = this.calculation;
//     this.coupon['offer'] = this.offer;
//     // this.coupon['trial'] = this.trial;
//     this.coupon['trialVal'] = "add";
//     this.messageToEmit.emit(this.coupon);
//     this.isHidden = true;
//     this.errorMessage = '';
//     this.successMessage = res['message'];
//     this.disableTextbox = true;
//     res['code'] = this.code;
//     localStorage.setItem('couponResponse', JSON.stringify(res));
//   }
//   removeSpace() {
//     this.code = this.code.trim();
//     if (this.code === '') {
//       this.errorMessage = '';
//       this.submitted = true;
//     } else {
//       this.submitted = false;
//     }
//   }
//   clearValue() {
//     this.submitted = true;
//     this.isHidden = false;
//     this.code = '';
//     this.successMessage = '';
//     this.errorMessage = '';
//     this.receivedAmount = this.tempAmount;
//     this.coupon['amount'] = this.receivedAmount;
//     this.coupon['trialVal'] = "remove";
//     this.messageToEmit.emit(this.coupon);
//     this.disableTextbox = false;
//     localStorage.removeItem('couponResponse');
//   }
//   checkInvalid() {
//     if (this.code) {
//       this.errorMessage = '';
//     }
//   }
// }



import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})

export class CouponComponent implements OnInit {
  @Input() receivedAmount: any;
  @Input() trVal: any;
  @Output() messageToEmit = new EventEmitter<any>();
  calculation: any;
  submitted = true;
  isRemove = false;
  isHidden = false;
  offer: any;
  errorMessage: any;
  successMessage: any;
  amount: any;
  tempAmount: any;
  error: any = {};
  code: any;
  disableTextbox = false;
  coupon: Object = {};
  coupon_id: any;
  isTrial: any;
  currencySymbol: any;
  constructor(private apiService: ApiService) {
  }
  ngOnInit() {
    this.isTrial = this.trVal;
    console.log(this.isTrial);
    this.tempAmount = this.receivedAmount;
    const couponResponse = localStorage.getItem('couponResponse');
    if (couponResponse && couponResponse !== '') {
      const code = JSON.parse(couponResponse);
      this.code = code['code'];
      this.couponCalculation(JSON.parse(couponResponse));
    }
  }
  /**
   * for handling the coupons
   */
handleCoupon() {
    this.isHidden = false;
    if (this.code !== undefined) {
      this.apiService.callPostAPI('v2/coupon/verify', { 'code': this.code, 'is_trial':this.isTrial },
        environment.paymentApiUrl).subscribe(res => {
          this.couponCalculation(res);
        },
          err => {
            this.successMessage = '';
            this.errorMessage = err['error']['message'];
            this.receivedAmount = this.tempAmount;
          });
    }
  }
  /**
   * for coupon calculations
   * @param res
   */
  couponCalculation(res) {
    this.offer = res['response'].offer;
    this.coupon_id = res['response'].coupon_id;
    if (res['response']['offer_type'] === 'percentage') {
      this.calculation = this.receivedAmount * this.offer / 100;
      this.calculation = Math.round(this.calculation);
      this.receivedAmount = this.receivedAmount - this.calculation;
    } else {
      this.calculation = this.offer;
      this.receivedAmount = this.receivedAmount - this.offer;
    }
    this.currencySymbol = res['response'].mycurrency;
    this.coupon['amount'] = this.receivedAmount;
    this.coupon['code'] = this.code;
    this.coupon['coupon_id'] = this.coupon_id;
    this.coupon['trialVal'] = 'add';
    this.messageToEmit.emit(this.coupon);
    this.isHidden = true;
    this.errorMessage = '';
    this.successMessage = res['message'];
    this.disableTextbox = true;
    res['code'] = this.code;
    localStorage.setItem('couponResponse', JSON.stringify(res));
  }
  removeSpace() {
    this.code = this.code.trim();
    if (this.code === '') {
      this.errorMessage = '';
      this.submitted = true;
    } else {
      this.submitted = false;
    }
  }
  clearValue() {
    this.submitted = true;
    this.isHidden = false;
    this.code = '';
    this.successMessage = '';
    this.errorMessage = '';
    this.receivedAmount = this.tempAmount;
    this.coupon['amount'] = this.receivedAmount;
    this.coupon['trialVal'] = 'remove';
    this.messageToEmit.emit(this.coupon);
    this.disableTextbox = false;
    localStorage.removeItem('couponResponse');
  }
  checkInvalid() {
    if (this.code) {
      this.errorMessage = '';
    }
  }
}
